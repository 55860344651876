$(document).ready(function() {
    // Leestijd tonen op nieuwsberichten
    if ($('#CCIPlaceHolder_Sidebar .large-4').length > 0 && $('#CCIPlaceHolder_Content .large-8 article time').length > 0) {
        var text = $('#CCIPlaceHolder_Content section').text();
        var words = text.split(" ").length - 1
        var readingTime = words / 250;
        readingTime = Math.round(readingTime);
        if (readingTime < 1) {
            readingTime = 1;
        }

        if(readingTime === 1){
            $("time").append("<i class='fa fa-fw'></i><span class='leestijd'> Leestijd:"+ readingTime +" minuut</span>")
        }
        else{
            $("time").append("<i class='fa fa-fw'></i><span class='leestijd'> Leestijd:"+ readingTime +" minuten</span>")
        }
    }
    if ($('#STSLedenzoekForm label[for*="_attributecheckbox_28"]').length > 0) {
    }

    //replace loading text....
    if($("iframe#iframe_iframe").length > '0'){
        var loading = $("iframe#iframe_iframe").prev().html();
        var res = loading.replace("Bezig met laden...", "");
        $("iframe#iframe_iframe").prev().html(res)
    };

    // redirect na ww wijzigen
    if($('#Block_ChangePassword_pnl_changepassword').length > 0){
        if($('#lbl_successmessage').length > 0){
            $(location).attr('href', 'https://www.knsnet.nl/mijn-kns')
        }
    }

    // function getPageID( name ) {
    //     //     name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    //     //     var regexS = "[\\?&]"+name+"=([^&#]*)";
    //     //     var regex = new RegExp( regexS );
    //     //     var results = regex.exec( document.getElementsByTagName("form")[0].getAttribute("action") );
    //     //     if( results == null )
    //     //         return "";
    //     //     else
    //     //         return results[1];
    //     // }
    //     //
    //     // var pageID = getPageID('id');
    //     // if (pageID === '5906') {
    //     //     fontsize();
    //     // }

    fontsize();
    initFontSize();

});

$(document).ajaxStop(function() {
    if ($('span.searchString').length > 0) {
        var huidigeLabel = $('span.searchString').text();
        if (huidigeLabel.indexOf('U heeft') >= 0) {
            $('span.searchString').text('Je hebt gezocht op de term: ');
        }
    }
    if ($('span.type').length > 0) {
        $('span.type').each(function() {
            var huidigeLabel = $(this).text();
            if (huidigeLabel.indexOf('PAGE') >= 0) {
                $(this).text('informatiepagina');
            }
        })
    }
    if ($('span.type').length > 0) {
        $('span.type').each(function() {
            var huidigeLabel = $(this).text();
            if (huidigeLabel.indexOf('SP_BIJEENKOMST') >= 0) {
                $(this).text('Bijeenkomst');
            }
        })
    }
    if ($('#STSLedenzoekForm label[for*="_attributecheckbox_28"]').length > 0) {
        $('#STSLedenzoekForm label[for*="_attributecheckbox_28"]').text('Ja');
        $('#STSLedenzoekForm fieldset').show();
    }
    if ($('span[id*=Block_MemberSearch_lblBedrijf]').length > 0) {
        $('span[id*=Block_MemberSearch_lblBedrijf]').text('Naam slagerij');
    }

});

// functions
function fontsize() {
    var fonts = $("<div id='fontSize'><span class='js-font' data-size='small' style='font-size:12px;'>a</span><span class='js-font' data-size='regular' style='font-size:16px;'>a</span><span class='js-font' data-size='large' style='font-size:32px;'>a</span></div>");
    $(fonts).prependTo(".topBar.right");

    $('.js-font').on('click', function() {
        event.preventDefault();
        $('body').attr('class', '').addClass($(this).data('size'));
        // re-calculate height of blocks
        $(document).foundation('equalizer','reflow');

        // set setting in localstorage, and apply changes directly without refresh
        var current = $('body').attr('class');
        localStorage.setItem("fontSize", current);
    });
}

function initFontSize() {
    switch(localStorage.fontSize) {
        case "small":
            console.log('initfontsize: small font chosen');
            $("body").addClass("small");
            break;

        case "regular":
            console.log('initfontsize: regular font chosen');
            $("body").addClass("regular");
            break;

        case "large":
            console.log('initfontsize: large font chosen');
            $("body").addClass("large");
            break;
    }
}